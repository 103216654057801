import { GET_ENROLL_STUDENT_SUCCESS, GET_ENROLL_STUDENT_REQUESTED, GET_ENROLL_STUDENT_FAILURE, GET_CLASSLIST_SUCCESS, SELECT_STUDENT_TO_ENROLL, SELECT_CLASSITEM_FOR_ENROLL, UNSELECT_CLASSITEM_FOR_ENROLL } from "./EnrollStudentType.js"

const initialState = {
  enrollStudents: [],
  enrollTeachers: [],
  enrollStudentData: [],
  classData: [],
  selectedClassItem: null,
  selectedStudents: []
}

const EnrollStudentReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "Student_Request": {
      break
    }
    case "Student_Success": {
      newState = {
        ...state,
        enrollStudents: action.data
      }
      break
    }
    case "Student_Failure": {
      break
    }
    case "Teacher_Success": {
      newState = { ...state, enrollTeachers: action.data }
      break
    }
    case "PostEnrollStudentData_Success": {
      //newState = { ...state, enrollStudentData: action.data }
      break
    }
    case GET_ENROLL_STUDENT_SUCCESS: {
      newState = { ...state, enrollStudentData: action.payload }
      break
    }
    case UNSELECT_CLASSITEM_FOR_ENROLL:
      newState = { ...state, selectedClassItem: null, selectedStudents: [] }
      break
    case GET_CLASSLIST_SUCCESS: {
      newState = { ...state, classData: action.payload }
      break
    }
    case SELECT_STUDENT_TO_ENROLL: {
      let filtered = state.selectedStudents.filter(item => item == action.payload)
      if (filtered != null && filtered != undefined && filtered.length > 0) {
        let arr = state.selectedStudents.filter(item => item != action.payload)
        newState = { ...state, selectedStudents: arr }
      }  else {
        let arr = [...state.selectedStudents,action.payload]
        let newArray = [];
        let uniqueObject = {};
        let newArray2 = [];
        let newArray3 = [];
        let newArray5 = [];
        for (let i in arr) {
            let objTitle = arr[i]['studentId']; 
            newArray2.push(parseInt(objTitle))
            uniqueObject[objTitle] = arr[i]; 
        }
        for (let i in uniqueObject) { 
            newArray3.push(i)
            newArray.push(uniqueObject[i]);
        }
        var x = newArray2;
        var y = x.slice().sort();
        var newArr = [];
        for(var i = 0; i<y.length; i++){
        if(y[i] != y[i+1]){
            var st = y[i]
            st.toString()
            newArr.push(y[i].toString());
        }else{
            i++;
        }
        }
        for (let i in newArr) { 
            newArray5.push(uniqueObject[newArr[i]]); 
        }
        newState = {...state, selectedStudents: newArray5}
    }

      break
    }
    case SELECT_CLASSITEM_FOR_ENROLL: {
      newState = { ...state, selectedClassItem: action.payload }
      break
    }
  }
  return newState
}

export default EnrollStudentReducer
