
const initialState = {
    studentDetails: [],
}

const StudentDetailReducer = (state = initialState, action) => {
    var newState = { ...state }
    switch (action.type) {
        case 'GetStudentDetails_Success':
            newState = { ...state, studentDetails: action.array }
            break
      
    }
    return newState

}

export default StudentDetailReducer