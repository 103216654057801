import { combineReducers } from "redux";
import TeacherReducer from "./Teacher/TeachHome/TeacherReducer";
import loginReducer from "./login/loginReducer";
import ChildPlanningReducer from "./Parent/ChildPlanningReducer";
import RosterHomeReducer from "./Teacher/Reports/RosterHomeReducer";
import AdmissionsReducer from "./Teacher/Reports/AdmissionsReportReducer";
import StudentListReducer from "./Teacher/Reports/StudentListReducer";
import EnrichmentEnrollmentReducer from "./Teacher/Reports/EnrichmentEnrollmentReducer";
import ContactListReducer from "./Teacher/Reports/ContactListReducer";
import StudentImmunReducer from "./Teacher/Reports/StudentImmunReducer";
import EmployeeReducer from "./Teacher/Reports/EmployeeReportReducer";
import checkListReducer from "./EditChecklist/ChecklistReducer";
import TeacherPlanningReducer from "./Teacher/TeacherPlanning/TeacherPlanningReducer";
import EnrollStudentReducer from "./Admin/EnrollStudent/EnrollStudentReducer";
import AttendanceRecordReducer from "./Attendance/AttendanceRecordReducer";
import InquiryReducer from "./InquiryData/InquiryReducer";
import ChildProfileReducer from "./ChildProfile/ChildProfileReducer";
import schoolLevelReducer from "./YearSetup/SchoolLevelReducer";
import RegisterReducer from "./Register/RegisterReducer";
import SuperAdminReducer from "./SuperAdmin/SuperAdminReducer";
import NewApplicantReducer from "./Admin/NewApplicant/NewApplicantReducer";
import OnBoardReducer from "./OnBoard/OnBoardReducer";
import MeetingReducer from "./Admin/Calendar/MeetingReducer";
import AdmissionReducer from "./Admin/Admission/AdmissionReducer";
import PromoteStudentReducer from "./Admin/PromoteStudent/PromoteStudentReducer";
import PopupLessonData from "./Teacher/AddNewLessonPopUp/AddNewLessonPopUpReducer";
import uploadFileReducer from "./Admin/Setting/UploadFileReducer";
import eventcalenderreducer from "./EventCalender/eventcalenderreducer";
// import PromoteStudentReducer from './Users/UserListReducer'
import UserListReducer from "./Users/UserListReducer";
import CandidatesReducer from "./Admin/AdmissionCandidates/CandidatesReducer";
import PdfReducer from "./ChecklistPdf/PdfReducer";
import EmergencyContactReducer from "./EmergencyContact/EmergencyContactReducer";
import ApprovedPickupListReducer from "./ApprovedPickupList/ApprovedPickupListReducer";
import HealthInfoReducer from "./HealthInformation/HealthInfoReducer";
import StudentDetailReducer from "./StudentDetails/StudentDetailReducer";
import AdminSearchReducer from "./Admin/AdminSearch/AdminSearchReducer";
import classRoomAndStudenthealthReducer from "./Teacher/Reports/classRoomAndStudentHealthInfoReducer";
import attendanceReportReducer from "./Teacher/Reports/AttendanceReportReducer";
import attendanceSummaryReportReducer from "./Teacher/Reports/AttendanceSummaryReportReducer";
import schoolDirectoryReducer from "./Teacher/Reports/SchoolDirectoryReducer";
import ClassroomNameDirectoryReducer from "./Teacher/Reports/ClassroomNameDirectoryReducer";
import schoolDirectoryFormReducer from "./Teacher/Reports/SchoolDirectoryFormReducer";
import StudentHealthFormReducer from "./Teacher/Reports/StudentHealthFormReducer";
import AdmissionsReportCurrentStudentsReducer from "./Teacher/Reports/admissionsReportCurrentStudentsReducer";
import ImmunDetailReducer from "./Teacher/Reports/ImmuneReportDetailReducer"
import LabelReducer from "./label/LabelReducer";


const rootReducer = combineReducers({
  login: loginReducer,
  teacherHome: TeacherReducer,
  childPlanning: ChildPlanningReducer,
  rosterReducer: RosterHomeReducer,
  studentListReducer: StudentListReducer,
  enrollmentReport: EnrichmentEnrollmentReducer,
  contactList: ContactListReducer,
  studentImmunReducer: StudentImmunReducer,
  employeeReducer: EmployeeReducer,
  editCheclist: checkListReducer,
  teacherPlanning: TeacherPlanningReducer,
  attendanceSummary: AttendanceRecordReducer,
  InquiryData: InquiryReducer,
  ChildProfileSummary: ChildProfileReducer,
  SchoolLevelSummary: schoolLevelReducer,
  EnrollStudents: EnrollStudentReducer,
  RegisterReducer: RegisterReducer,
  superAdmin: SuperAdminReducer,
  NewApplicantSummary: NewApplicantReducer,
  OnBoard: OnBoardReducer,
  MeetingSummary: MeetingReducer,
  Admission: AdmissionReducer,
  PromoteSummary: PromoteStudentReducer,
  PopUpLessonData: PopupLessonData,
  UserList: UserListReducer,
  eventslist: eventcalenderreducer,
  CandidateSummary: CandidatesReducer,
  uploadFileData: uploadFileReducer,
  PdfFile: PdfReducer,
  EmergencyContactReducer: EmergencyContactReducer,
  ApprovedPickupListReducer: ApprovedPickupListReducer,
  HealthAndAllergyData: HealthInfoReducer,
  StudentDetails: StudentDetailReducer,
  adminSearch: AdminSearchReducer,
  classRoomAndStudenthealthReducer: classRoomAndStudenthealthReducer,
  attendanceReport: attendanceReportReducer,
  attendanceSummaryReport: attendanceSummaryReportReducer,
  schoolDirectory: schoolDirectoryReducer,
  ClassroomNameDirectoryReducer: ClassroomNameDirectoryReducer,
  schoolDirectoryForm: schoolDirectoryFormReducer,
  StudentHealthForm: StudentHealthFormReducer,
  admissionReducer: AdmissionsReducer,
  admissionCurrentStudents: AdmissionsReportCurrentStudentsReducer,
  immunReportDetailReducer: ImmunDetailReducer,
  LabelData: LabelReducer
});

export default rootReducer;
