const initialState = {
  childProfileData: [],
  childProfileArray: []
}

const ChildProfileReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "ChildList_REQUEST":
      break
    case "ChildList_SUCCESS":
      newState = { ...state, childProfileArray: action.array }
      break
    case "ChildList_FAILURE":
      break
    case "StudentData_SUCCESS":
      newState = { ...state, childProfileData: action.array }
      break
    case "StudentHealthData_SUCCESS":
      newState = { ...state, childHealthData: action.array }
      break
    case "StudentHealthDataPOST_SUCCESS":
      newState = { ...state, postChildHealthData: action.array }
      break
    case "postStudentPhotoData_SUCCESS":
      newState = { ...state, postChildHealthData: action.array }
      break
    case 'updateStudentData_SUCCESS':
      newState = { ...state, UpdateStatus: action.array }
      break
  }
  return newState
}

export default ChildProfileReducer
