
const initialState = {
    DueDateData: [],
    stepsData:[]
}

const CandidatesReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {


        case 'GetDueDate_SUCCESS':
            newState = { ...state, DueDateDataa: action.array }
            break
        case 'GetStepsSUCCESS':
            newState = { ...state, stepsData: action.array }
            break
            case 'postCheckListDataSuccess':
                newState = { ...state, poststepsData: action.array }
                break
                case 'postCheckListDataSuccessParent':
                    newState = { ...state, poststepsData: action.array }
                break
            

    }
    return newState;
}

export default CandidatesReducer;