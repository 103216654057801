const initialState = {
    lessonData: [] 
}

const AddNewLessonPopUpReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'getLessonData_SUCCESS':
            newState = { lessonData : action.array }
            break
            case 'post_PopupForm_Data': {
                newState = { ...state, popUpDataArray: action.data }
                break
            }
            
    }
    return newState;
}

export default AddNewLessonPopUpReducer;