import { GET_STUDENT_HEALTH_FORM } from "./StudentHealthFormAction";

const initialStudentHealthForm = [];
const StudentHealthFormReducer = (state = initialStudentHealthForm, action) => {
  switch (action.type) {
    case GET_STUDENT_HEALTH_FORM:
      return {
        ...state,
        StudentHealthForm: action.payload,
      };
    default:
      return state;
  }
};

export default StudentHealthFormReducer;
