const initialState = {
  schoolLevelArray: [],
  schoolArray: [],
  termdataArray: [],
  schoolLevelSuccessArray: [],
  attendanceData: [],
}

const schoolLevelReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "SchoolLevelList_REQUEST":
      break
    case "SchoolLevelList_SUCCESS":
      newState = { ...state, schoolLevelArray: action.array }
      break
    case "SchoolLevelList_FAILURE":
      break
    case "setSchoolARRAY_SUCCESS":
      newState = { ...state, schoolLevelArray: action.array }
      break
    case "settermARRAY_SUCCESS":
      newState = { ...state, schoolLevelArray: action.array }
      break
    case "setClassARRAY_SUCCESS":
      newState = { ...state, schoolLevelArray: action.array }
      break
    case "GET_TERM_DATA_SUCCESS":
      newState = { ...state, termdataArray: action.array }
      break
    case "PostClassData_func":
      newState = { ...state, PostClass: action.array }
      break
    case "PostSchoolData_func":
      newState = { ...state, PostSchool: action.array }
      break
    case "SchoolLevelList_List":
      newState = { ...state, schoolLists: action.array.data }
      break
    case "GET_CLASS_LEVEL":
      newState = { ...state, classLevel: action.array.data }
      break
      case "GET_CLASS_LEVEL_SUCCESS":
      newState = { ...state, classLevelSuccess: action.array }
      break
    case "UPDATE_CLASS":
      newState = { ...state, updateClass: action.array.data }
      break
    case "UPDATE_SCHOOL":
      newState = { ...state, updateSchool: action.array.data }
    case "termSUCCESS":
      newState = { ...state, UpdatedTerm: action.array }
      break
    case "getSchoolSUCCESS":
      newState = { ...state, schoolArray: action.array }
      break
      case "getSchoolLevelSUCCESS":
      newState = { ...state, schoolLevelSuccessArray: action.array }
      break
    case "postEnrooledAPISuccess":
      newState = { ...state, EnrolledData: action.array }
      break

    case "Add_Teacher":
      newState = { ...state, addTeacher: action.array }
      break
    case "GET_ALL_TEACHERS":
      newState = { ...state, allTeachers: action.array }
      break
    case "getStudentListSuccess":
      newState = { ...state, studentData: action.array }
      break
    case "get_ClassSetup_By_SchoolId_Sucess":
      newState = { ...state, teacherData: action.array }
      break
    case "teacherPOST_SUCCESS":
      newState = { ...state, teacherData: action.array }
      break
    case "postNewTeacher_Success":
      newState = { ...state, teacherData: action.array }
      break
    case "get_ClassSetup_Sucess":
      newState = { ...state, classData: action.array }
      break
    case "getFilterAttendance_Success":
      newState = { ...state, attendanceData: action.array }
      break
    }
  
  return newState
}

export default schoolLevelReducer
