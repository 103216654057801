const initialState = {
    immunizationReportDetailData: [],
  };
  
  const ImmunDetailReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
      case "ImmunReportDetail_REQUEST":
        break;
      case "ImmunReportDetail_SUCCESS":
        newState = { immunizationReportDetailData: action.array };
        break;
      case "StudentImmunReport_FAILURE":
        break;
      case "ImmunneDetailAPI_SUCCESS":
        newState = { immunizationReportDetailData: action.array };
        break;
      case "StudentImmunneByClassAPI_SUCCESS":
        newState = { immunizationReportDetailTeacherData: action.array };
        break;
    }
    return newState;
  };
  
  export default ImmunDetailReducer;