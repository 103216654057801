import { GET_ATTENDANCE_REPORT } from "./AttendanceReportAction";

const inititalAttendanceReport ={
  attendanceReportData : []
}

const attendanceReportReducer = (state = inititalAttendanceReport, action) => {
  var newState = { ...state };
  switch (action.type) {
    case `GET_ATTENDANCE_REPORT`:
      newState = {...state, attendanceReportData: action.payload}
      break;
    case 'getAttendanceReportClass_SUCCESS':
      newState = {  ...state,attendanceReportDataClass: action.array }
      break
  }
  return newState;
};

export default attendanceReportReducer;
