const initialState = {
  usersOfSchool: [],
  schoolLevel: [],
  usersRoles:[],
  userTeacherRoles:[],
};

const AdminSearchReducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case "getAdminSearch_Success":
      newState = { ...state, usersOfSchool: action.data };
      break;
    case "getSchoolLevelSUCCESS":
      newState = { ...state, schoolLevel: action.data };
      break;
    case "getAdminRoleAdd_Success":
      newState = { ...state, usersRoles: action.data };
      break;
    case 'getTeacherRoleAdd_Success':
      newState = {...state, userTeacherRoles: action.data}
      break;
    case 'deleteRole_Success':
      newState = {...state}
      break;
  }
  return newState;
};

export default AdminSearchReducer;
