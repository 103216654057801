const initialState = {
    InquiryArray: [],
    otp: '',
    inquiryUserModel: []
}

const InquiryReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'ARRAY_REQUEST':
            break
        case 'ARRAY_SUCCESS':
            newState = { ...state, InquiryArray: action.array }
            break
        case 'ARRAY_FAILURE':
            break
        case 'setARRAY_SUCCESS':
            newState = {...state, InquiryArray: action.array }
            break
        case 'sendOtpToEmail_SUCCESS':
            newState = { otp: action.otp }
            break
        case 'setPostInquiry_Success':
            newState = { ...state, inquiryUserModel: action.data }
            break

    }
    return newState;
}

export default InquiryReducer;