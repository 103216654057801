const initialState = {
  modules: [],
  allSchools: [],
  allSchoolsList: [],
  updateSchool: [],
  usersOfSchool: [],
  getSchool: [],
  timestamp: undefined,
  isSearching: false,
};

function actionIsValid(state, data) {
  return data.timestamp >= state.timestamp;
}

const SuperAdminReducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case "GetModules_Success":
      newState = { ...state, modules: action.array };
      break;
    case "GetAllSchool_Success":
      newState = { ...state, allSchools: action.array };
      break;
    case "GetAllSchoolList_Success":
      newState = { ...state, allSchoolsList: action.array };
      break;
    case "updateSchoolSuccess_SUCCESS":
      newState = { ...state, updateSchool: action.array };
      break;
    case "GetUsersOfSchool_Success":
      newState = { ...state, usersOfSchool: action.array };
      break;

    case "GetSchoolById_Success":
      newState = { ...state, getSchool: action.array.data };
      break;
    case "updatepayment_Success":
      newState = { ...state, updatePayment: action.array };
      break;
    case "SEARCH_POST_START":
      newState = {
        ...state,
        // usersOfSchool: action.array.searchResults,
        timestamp: action.array.timestamp,
        isSearching: true,
      };
      break;
    case "SEARCH_POST_SUCCESS": {
      if (actionIsValid(state, action.array)) {
        newState = {
          ...state,
          usersOfSchool: action.array.searchResults,
          timestamp: action.array.timestamp,
          isSearching: false,
        };
      }
      break;
    }
  }
  return newState;
};

export default SuperAdminReducer;
