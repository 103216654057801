const initialState = {
  classwiseArray: [],
  attendanceArray: [],
};

const AttendanceRecordReducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case "AttendanceList_REQUEST":
      break;
    case "AttendanceList_SUCCESS":
      newState = { ...state, classwiseArray: action.array };
      break;
    case "AttendanceList_FAILURE":
      break;
    case "Attendance_SUCCESS":
      newState = { ...state, attendanceArray: action.array };
      break;
    case "getClassAttendance_SUCCESS":
      newState = { ...state, classwiseArray: action.array };
      break;
    case "STUDENT_BY_CLASS":
      newState = { ...state, Studentlisting: action.array };
      break;
    case "getYearlyAttendanceSuccess":
      newState = { ...state, yearlytAttendanceArray: action.array };
      break;
    case "getStudentAttendanceSuccess":
      newState = { ...state, studentAttendance: action.array };
      break;
    case "editStudentAttendanceSuccess":
      newState = { ...state, editstudentAttendance: action.array };
      break;
    case "postDismissSuccess":
      newState = { ...state, postDismissSuccess: action.array };
      break;
      case "dismissAllStudentSuccess":
      newState = { ...state, dismissAllStudentSuccess: action.array };
      break;
  }
  return newState;
};

export default AttendanceRecordReducer;
