import axios from "axios";
import { BASE_URL_API_CONSTANT } from "./../../baseUrl/ApiConstants";

export const GET_CLASSROOM_NAME_DIRECTORY = "GET_SCHOOL_DIRECTORY";

const getClassroomNameDirectory = (data) => {
  return {
    type: GET_CLASSROOM_NAME_DIRECTORY,
    payload: data,
  };
};


export const getClassroomNameDirectoryApi = (school_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: BASE_URL_API_CONSTANT + `/getClassroomNameDirectory/${school_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
        .then((res) => {
          dispatch(getClassroomNameDirectory(res.data));
          resolve(res.data);
        })
        .catch((err) => reject(err.message));
    });
  };
};

