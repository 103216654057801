
import {TEACHER_PLANNING_SELECT_STUDENT, TEACHER_PLANNING_SELECT_STUDENT_FOR_NEW_TASK, TEACHER_PLANNING_REMOVE_STUDENT_FOR_NEW_TASK} from './TeacherPlanningTypes.js'
const initialState = {
    studentsData: [],
    selectedWeek: [],
    selectedStudent: null,
    selectStudentForNewTask : []
}

const TeacherPlanningReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'SELECTED_WEEK_SUCCESS':
            newState = {...state, selectedWeek: action.payload}
            break
        case 'Planning_REQUEST':
            break
        case 'Planning_SUCCESS':
            newState = { studentsData: action.array }
            break
        case 'Planning_FAILURE':
            break
        case 'Set_Planning':
            newState = { studentsData : action.array }
            break
        case 'getCardData_SUCCESS': {
            newState = { ...state, cardDataArray: action.array }
            break
        }
        case 'update_card_Success': {
            newState = state
            break
        }
        case TEACHER_PLANNING_SELECT_STUDENT:
            newState = {...state, selectedStudent:action.payload}
            break
        case TEACHER_PLANNING_SELECT_STUDENT_FOR_NEW_TASK :
            var arr1 = state.selectStudentForNewTask
            arr1.push(action.payload)
            newState = { ...state, selectStudentForNewTask : arr1}
            break;
        case TEACHER_PLANNING_REMOVE_STUDENT_FOR_NEW_TASK :
            let arr2 = state.selectStudentForNewTask.filter( item => (item.studentId != action.payload.studentId))
            newState = { ...state, selectStudentForNewTask : arr2}
            break;
    }
    return newState;
}

export default TeacherPlanningReducer;