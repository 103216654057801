const initialState = {
  AllStudentData: [],
  AllInQuiryAndRegData: []
}

const NewApplicantReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "GET_AllStudentData_Success":
      newState = { ...state, AllStudentData: action.array }
      break
    case "GET_AllStudentInQuiryAndRegistrationData_Success":
      newState = { ...state, AllInQuiryAndRegData: action.array }
      break
    case "eidt_enablestudentformSuccess":
      newState = { ...state, formdata: action.array }
      break
  }
  return newState
}

export default NewApplicantReducer
