import {
    USER_LIST_REQUESTED,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL } from './UserListTypes.js'


    const initialState = {
        users: []
    }
    
    const UserListReducer = (state = initialState, action) => {
        var newState = { ...state }
        switch (action.type) {
            case USER_LIST_SUCCESS:
                newState = { ...state, users: action.payload }
                break
        }
        return newState
    
    }
    
    export default UserListReducer
