const initialState = {
    employeeReportData : []
}

const EmployeeReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'EmployeeReport_REQUEST':
            break
        case 'EmployeeReport_SUCCESS':
            newState = {employeeReportData:action.array}
            break
        case 'EmployeeReport_FAILURE':
            break
            case 'EmployeeAPI_SUCCESS':
                newState = {employeeReportData:action.array}
                break
        case 'EmployeeByClassAPI_SUCCESS':
            newState = {employeeReportTeacherData:action.array}
            break
                
            
    }
    return newState;
}

export default EmployeeReducer;