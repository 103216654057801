const initialState = {
    admissionReportData : []
}

const AdmissionsReportReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'AdmissionReport_REQUEST':
            break
        case 'AdmissionReport_SUCCESS':
            newState = { ...state, admissionReportData:action.payload}
            break
        case 'AdmissionReport_FAILURE':
            break
            case 'RooaterByHome_SUCCESS':
                newState = {  ...state, admissionReportData: action.payload}
                break
        case 'getAdmissionReportClass_SUCCESS':
            newState = {  ...state,admissionReportDataClass: action.array }
            break
    }
    return newState;
}

export default AdmissionsReportReducer;