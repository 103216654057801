import axios from "axios";
import { BASE_URL_API_CONSTANT } from "./../../baseUrl/ApiConstants";

export const GET_STUDENT_HEALTH_FORM = "GET_SCHOOL_DIRECTORY_FORM";

const getStudentHealthForm = (data) => {
  return {
    type: GET_STUDENT_HEALTH_FORM,
    payload: data,
  };
};


export const getStudentHealthFormApi = (school_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: BASE_URL_API_CONSTANT + `/getStudentHealthForm/${school_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
        .then((res) => {
          dispatch(getStudentHealthForm(res.data));
          resolve(res.data);
        })
        .catch((err) => reject(err.message));
    });
  };
};

