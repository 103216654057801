const initialState = {
    classRoomAndStudenthealthData : []
}

const classRoomAndStudenthealthReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'classRoomAndStudenthealthData_REQUEST':
            break
        case 'classRoomAndStudenthealthData_SUCCESS':
            newState = {classRoomAndStudenthealthData:action.array}
            break
        case 'classRoomAndStudenthealthData_FAILURE':
            break
        case 'classRoomAndStudentHealthInfoClass_SUCCESS':
            newState = {  ...state,classRoomAndStudenthealthDataClass: action.array }
            break
    }
    return newState;
}

export default classRoomAndStudenthealthReducer;