const initialState = {
    LabelsArray: [],
}

const LabelReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'Labels_SUCCESS':
            let nl = {};
            action.labels.map(l => nl[l.id] = l); // create lookup object indexed on id.
            newState = { ...state, LabelsArray: nl }
            break;
    }
    return newState;
}

export default LabelReducer;