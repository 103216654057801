const initialState = {
  meetingDataArray: [],
  postmeetingDataArray: [],
  posteventDataArray: [],
};

const MeetingReducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case "Meeting_Request": {
      break;
    }
    case "Meeting_Success": {
      newState = {
        ...state,
        meetingDataArray: action.data,
      };
      break;
    }

    case "postMeeting_Success": {
      newState = {
        ...state,
        postmeetingDataArray: action.data,
      };
      break;
    }
    case "postEvent_Success": {
      newState = { ...state, posteventDataArray: action.data };
      break;
    }
    case "updateMeetingPopUpDataSuccess": {
      //  newState = { ...state, posteventDataArray: action.data }
      break;
    }
    case "updateEventPopUpDataSuccess": {
      //  newState = { ...state, posteventDataArray: action.data }
      break;
    }
    case "deleteMeetingDataSuccess": {
      newState = { ...state, updatedMeetingData: action.data };
      break;
    }
    case "deleteEventDataSuccess": {
      newState = { ...state, updatedEventData: action.data };
      break;
    }

    case "getMeeting_Success": {
      newState = { ...state, meetingDataArray: action.data };
      break;
    }
    case "getParentList_Success": {
      newState = { ...state, parentListArray: action.data };
      break;
    }
    case "postPopUpMeeting_Success": {
      newState = { ...state, parentListArray: action.data };
      break;
    }
    case "getCalender_Success": {
      newState = { ...state, parentListArray: action.data };
      break;
    }
    case "TEACHER_MEETING_USER_SUCCESS": {
      newState = { ...state, teacherMeetingUser: action.data }
    }
  }
  return newState;
};

export default MeetingReducer;
