const initialState = {
  childPlanningArray: []
}

const ChildPlanningReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "ARRAY_REQUEST":
      break
    case "ARRAY_SUCCESS":

      newState = { ...state, childPlanningArray: action.array.data }
      break
    case "ARRAY_FAILURE":
      break
    case "ARRAY_SUCCESS_PARENT":
      newState = { ...state, parentChild: action.array.data }
      break
    case "APPLICANT_SUCCESS":
      newState = { ...state, ApplicantSummaryData: action.array.data }
      break
    case "childprogress_SUCCESS":
      newState = { ...state, childProgressData: action.array.data }
      break
    case "getParentPhotos_Success":
      newState = { ...state, parentPhotoArray: action.array }
      break
    case "ClassbyStudentId_SUCCESS":
      newState = { ...state, childData: action.array }
      break
    case "PostParent2User_Success":
      newState = { ...state, parent2info: action.array }
      break
  }
  return newState
}

export default ChildPlanningReducer
