var location = window.location.host;
var BASE_URL_API_CONSTANT;
var BASE_URL_AUTH_API_CONSTANT;
var BASE_URL_STAGE_API_CONSTANT;
var BASE_URL_BUCKETEER;
var CAPTCHA_SITE_KEY;

CAPTCHA_SITE_KEY = "6LcX6ychAAAAAI7wh__Yzqsyh626FL1FeHY2TQlp";
if (location == "clever-dev.herokuapp.com") {

  BASE_URL_API_CONSTANT = "https://damp-badlands-00817.herokuapp.com/api/v1";
  BASE_URL_AUTH_API_CONSTANT = "https://damp-badlands-00817.herokuapp.com/auth/oauth";
  BASE_URL_STAGE_API_CONSTANT = "https://damp-badlands-00817.herokuapp.com";
  BASE_URL_BUCKETEER = "https://bucketeer-dbc4a465-1d79-4eef-9140-c7297ea93b19.s3.amazonaws.com";

} else if (location == "localhost:3000") {

  // Localhost dev
  BASE_URL_API_CONSTANT = 'http://localhost:3005/api/v1'
  BASE_URL_AUTH_API_CONSTANT = 'http://localhost:3005/auth/oauth'
  BASE_URL_STAGE_API_CONSTANT = 'http://localhost:3005'
  BASE_URL_BUCKETEER = 'https://bucketeer-dbc4a465-1d79-4eef-9140-c7297ea93b19.s3.amazonaws.com'

  // Staging
  // BASE_URL_API_CONSTANT = "https://clever-be-production.herokuapp.com/api/v1";
  // BASE_URL_AUTH_API_CONSTANT = "https://clever-be-production.herokuapp.com/auth/oauth";
  // BASE_URL_STAGE_API_CONSTANT = "https://clever-be-production.herokuapp.com";
  // BASE_URL_BUCKETEER = "https://bucketeer-ccfee374-2394-4d85-8856-00e06a276268.s3.amazonaws.com";

  // Production
  // BASE_URL_API_CONSTANT = 'https://damp-badlands-00817.herokuapp.com/api/v1'
  // BASE_URL_AUTH_API_CONSTANT = 'https://damp-badlands-00817.herokuapp.com/auth/oauth'
  // BASE_URL_STAGE_API_CONSTANT = 'https://damp-badlands-00817.herokuapp.com'
  // BASE_URL_BUCKETEER = 'https://bucketeer-dbc4a465-1d79-4eef-9140-c7297ea93b19.s3.amazonaws.com'

} else {
  BASE_URL_API_CONSTANT = "https://clever-be-production.herokuapp.com/api/v1";
  BASE_URL_AUTH_API_CONSTANT = "https://clever-be-production.herokuapp.com/auth/oauth";
  BASE_URL_STAGE_API_CONSTANT = "https://clever-be-production.herokuapp.com";
  BASE_URL_BUCKETEER = "https://bucketeer-ccfee374-2394-4d85-8856-00e06a276268.s3.amazonaws.com";
}

export { BASE_URL_API_CONSTANT };
export { BASE_URL_AUTH_API_CONSTANT };
export { BASE_URL_STAGE_API_CONSTANT };
export { BASE_URL_BUCKETEER };
export { CAPTCHA_SITE_KEY };