const initialState = {
  registerData: [],
  countrylist: [],
  statelist: [],
  cities: [],
  formdetails: [],
  adminToolKeyData:[],
  parentToolKeyData:[],
  teacherToolKeyData : [],
  parentResponse: [],
  teacherResponse:[],
  parentResponseCount:[],
  teacherResponseCount:[],
  adminAddForm:[],
}

const RegisterReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "SetRegister_Success":
      newState = { ...state, registerData: action.array }
      break

    case "GET_COUNTRY_Success":
      newState = { ...state, countrylist: action.array }
      break

    case "GET_STATE_Success":
      newState = { ...state, statelist: action.array }
      break

    case "GET_CITY_Success":
      newState = { ...state, cities: action.array }
      break
    case "SET_USER_DETAILS":
      newState = { ...state, userDetails: action.array }
      break
    case "FindStudentByUser_Success":
      newState = { ...state, userDetails: action.array }
      break
    case "FormData_Success":
      newState = { ...state, formData: action.array }
      break
    case "getFormData_Success":
      newState = { ...state, formdetails: action.data }
      break
    case "updateCustomizeform_Success":
      newState = { ...state, editformdetails: action.array }
      break
    case "updateAdmissionForm_Success":
      newState = { ...state, editformdetails: action.array }
      break
    case "SET_AdminToolKeyForm_Success":
      newState = { ...state, adminToolKeyForm: action.array }
      break
    case "GET_FormToolKey_Success":
      newState = { ...state, adminToolKeyData: action.data }
      break
    case "GET_Parent_FormToolKey_Success":
      newState = { ...state, parentToolKeyData: action.data }
      break
      case "GET_Teacher_FormToolKey_Success":
      newState = { ...state, teacherToolKeyData: action.data }
      break
    case "SET_ParentToolKeyForm_Success":
      newState = { ...state, parentToolKeyForm: action.array }
      break
      case "SET_TeacherToolKeyForm_Success":
      newState = { ...state, teacherToolKeyForm: action.array }
      break
    case "updateParentToolKeyForm_success":
      newState = { ...state, editParentToolKeyForm: action.array }
      break
      case "updateTeacherToolKeyForm_success":
      newState = { ...state, editTeacherToolKeyForm: action.array }
      break
    case "GET_Parent_FormResponse_Success":
      newState = { ...state, parentResponse: action.data }
      break
      case "GET_Teacher_FormResponse_Success":
      newState = { ...state, teacherResponse: action.data }
      break
    case "getFormDataCount_Success":
      newState = { ...state, parentResponseCount: action.data }
      break
      case "getTeacher_FormDataCount_Success":
      newState = { ...state, teacherResponseCount: action.data }
      break
    case "updateAdminForm_success":
      newState = { ...state, adminAddForm: action.array }
      break 
      
  }
  return newState
}

export default RegisterReducer
