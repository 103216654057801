import axios from "axios";
import { BASE_URL_API_CONSTANT } from "./../../baseUrl/ApiConstants";

export const GET_ATTENDANCE_REPORT = "GET_ATTENDANCE_REPORT";

const getAttendanceReport = (data) => {
  return {
    type: GET_ATTENDANCE_REPORT,
    payload: data,
  };
};

const getAttendanceReportClassSuccess = array => {
  return {
    type: "getAttendanceReportClass_SUCCESS",
    array: array
  }
}

export const getAttendanceReportApi = (schoolid) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: BASE_URL_API_CONSTANT + `/getattendanceDetailedReport/${schoolid}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
        .then((res) => {
          dispatch(getAttendanceReport(res.data));
          resolve(res.data);
        })
        .catch((err) => reject(err.message));
    });
  };
};

export const getAttendanceReportByClass = data => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          axios({
              method: 'POST',
              url: BASE_URL_API_CONSTANT + `/getattendanceDetailedReportByclass`,
              data:data,
              headers: { 
                  // Authorization: `Bearer ${token}` 
                  Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
                }
          }).then(res => {
              dispatch(getAttendanceReportClassSuccess(res.data));
              resolve(res.data);
          }).catch(err => reject(err.message));
      })
}
}
