

const initialState = {
    classwiseArray : []
}

const EventsReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'AttendanceList_REQUEST':
            break
        case 'AttendanceList_SUCCESS':
            newState = {classwiseArray:action.array}
            break
        case 'EVENT_LIST':
            newState = { ...state, events: action.array }
            break
        case 'AttendanceList_FAILURE':
            break
    }
    return newState;
}

export default EventsReducer;