

const initialState = {
    rosterReportData : []
}

const RosterHomeReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'RosterReport_REQUEST':
            break
        case 'RosterReport_SUCCESS':
            newState = { ...state, rosterReportData:action.array}
            break
        case 'RosterReport_FAILURE':
            break
            case 'RooaterByHome_SUCCESS':
                newState = {  ...state, rosterReportData: action.array }
                break
        case 'RooaterByHomeClass_SUCCESS':
            newState = {  ...state, rosterReportDataClass: action.array }
                break
            
    }
    return newState;
}

export default RosterHomeReducer;