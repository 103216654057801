import {
    GET_PROMOTE_STUDENT_SUCCESS,
    GET_PROMOTE_STUDENT_REQUESTED,
    GET_PROMOTE_STUDENT_FAILURE,
    GET_CLASSLIST_FOR_PROMOTE_SUCCESS,
    SELECT_STUDENT_TO_PROMOTE,
    SELECT_CLASSITEM_FOR_PROMOTE,
    UNSELECT_CLASSITEM_FOR_PROMOTE,
    SELECT_STUDENT_TO_EMPTY
} from './PromoteStudentType.js'



const initialState = {
    promoteStudentArray: [],
    promoteStudentData: [],
    classData:[],
    selectedClassItem:null,
    selectedStudents: []
}

const PromoteStudentReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'AttendanceList_REQUEST':
            break
        case 'PromoteStudent_SUCCESS':
            newState = { promoteStudentArray: action.array }
            break
        case 'updatePromoteStudent_SUCCESS':
            newState = { promoteStudentArray: action.array }
            break
        case 'ClassData_SUCCESS':
            newState = {classData:action.array}
            break
        case GET_PROMOTE_STUDENT_SUCCESS: {
            newState = { ...state, promoteStudentData: action.payload}
            break;
        }
        case UNSELECT_CLASSITEM_FOR_PROMOTE :
            newState = {...state, selectedClassItem: null, selectedStudents: []}
            break;
        case GET_CLASSLIST_FOR_PROMOTE_SUCCESS : {
            newState = {...state, classData:action.payload}
            break
        }
        case SELECT_STUDENT_TO_EMPTY : {
            let emptyArr = [];
            newState = {...state, selectedStudents: emptyArr}
            break
        }
        case SELECT_STUDENT_TO_PROMOTE : {
            let filtered = state.selectedStudents.filter(item => (item == action.payload))
            if (filtered != null && filtered != undefined && filtered.length > 0){
                let arr = state.selectedStudents.filter(item => (item != action.payload))
                newState = {...state, selectedStudents: arr}
            }
            else {
                let arr = [...state.selectedStudents,action.payload]
                let newArray = [];
                let uniqueObject = {};
                let newArray2 = [];
                let newArray3 = [];
                let newArray5 = [];
                for (let i in arr) {
                    let objTitle = arr[i]['studentId']; 
                    newArray2.push(parseInt(objTitle))
                    uniqueObject[objTitle] = arr[i]; 
                }
                for (let i in uniqueObject) { 
                    newArray3.push(i)
                    newArray.push(uniqueObject[i]);
                }
                var x = newArray2;
                var y = x.slice().sort();
                var newArr = [];
                for(var i = 0; i<y.length; i++){
                if(y[i] != y[i+1]){
                    var st = y[i]
                    st.toString()
                    newArr.push(y[i].toString());
                }else{
                    i++;
                }
                }
                for (let i in newArr) { 
                    newArray5.push(uniqueObject[newArr[i]]); 
                }
                newState = {...state, selectedStudents: newArray5}
            }
            break
        }
        case SELECT_CLASSITEM_FOR_PROMOTE : {
            newState = {...state, selectedClassItem :action.payload}
            break
        }
    }
    return newState;
}

export default PromoteStudentReducer;