const initialState = {
    contactListReportData : []
}

const ContactListReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'ContactList_REQUEST':
            break
        case 'ContactList_SUCCESS':
            newState = {contactListReportData:action.array}
            break
        case 'ContactList_FAILURE':
            break
        case 'ContactListAPI_SUCCESS':
            newState = {contactListReportData:action.array}
            break
        case 'ContactListByClassAPI_SUCCESS':
            newState = {contactListReportTeacherData:action.array}
            break
            
    }
    return newState;
}

export default ContactListReducer;