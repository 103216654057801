const initialState = {
    HealthAndAllergyData: [],
    ShotTakenData:[],
    getPendingHealthShotsData:[]
}

const HealthInfoReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'HealthAndAllergyData_Post':
            newState = { ...state, HealthAndAllergyDataPOST: action.array }
            break
        case 'HealthAndAllergyData_GET':
            newState = { ...state, HealthAndAllergyData: action.array }
            break
        case 'HealthAndAllergyData_Update':
            newState = { ...state, HealthAndAllergyDataUpdate: action.array }
            break
        case 'get_PendingHealthshots':
            newState = { ...state, getPendingHealthShotsData: action.array }
            break
        case 'getShotsDatasuccess_Get':
            newState = { ...state, ShotTakenData: action.array }
            break
        case 'StudentShotDataSuccess_Update':
            newState = { ...state, ShotTakenDataUpdate: action.array }
            break
        case 'Post_AddShotToStudent':
            newState = { ...state, AddShotToStudentArray: action.array }
            break
    }
    return newState;
}

export default HealthInfoReducer;