

const initialState = {
    classwiseArray: []
}

const OnBoardReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'OnBoard_Post':
            newState = { ...state, OnBoardPOST: action.array }
            break
        case 'OnBoard_GET':
            newState = { ...state, OnBoardGET: action.array }
            break
        case 'AttendanceList_SUCCESS':
            newState = { classwiseArray: action.array }
            break
        case 'AttendanceList_FAILURE':
            break
    }
    return newState;
}

export default OnBoardReducer;