const initialState = {
    AdmissionArray: [],


}

const AdmissionReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {

        case 'Admission_SUCCESS':
            newState = { AdmissionArray: action.array }
            break
       
    }
    return newState;
}

export default AdmissionReducer;