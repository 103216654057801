

const initialState = {
    studentImmunReportData : []
}

const StudentImmunReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'StudentImmunReport_REQUEST':
            break
        case 'StudentImmunReport_SUCCESS':
            newState = {studentImmunReportData:action.array}
            break
        case 'StudentImmunReport_FAILURE':
            break
            case 'StudentImmunneAPI_SUCCESS':
                newState = {studentImmunReportData:action.array}
                break
                case 'StudentImmunneByClassAPI_SUCCESS':
                    newState = {studentImmunReportTeacherData:action.array}
                    break
                
            
    }
    return newState;
}

export default StudentImmunReducer;