const initialState = {
  enrollmentReportData: []
}

const EnrichmentEnrollmentReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "EnrollmentReport_REQUEST":
      break
    case "EnrollmentReport_SUCCESS":
      newState = { enrollmentReportData: action.array }
      break
    case "EnrollmentReport_FAILURE":
      break
    case "EnrollmentData_SUCCESS":
      newState = { enrollmentReportData: action.array }
      break
    case "EnrichmentEnrollment_SUCCESS":
      newState = { enrollmentReportData: action.array }
      break
      case "EnrollmentDataByClassApi_SUCCESS":
      newState = { enrollmentReportTeachertData: action.array }
      break
      
  }
  return newState
}

export default EnrichmentEnrollmentReducer
