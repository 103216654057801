const initialState = {
  materialArray: [],
  classLevelArray: []
}

const TeacherReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "ARRAY_REQUEST":
      break
    case "ARRAY_SUCCESS":
      newState = { ...state, materialArray: action.array }
      break
    case "getClassbyTeacherIdSuccess":
      newState = { ...state, schoolLevelArray: action.array }
      break
    case "postMatrialData_Success":
      newState = { ...state, matrialArray: action.array }
      break
    case "ARRAY_FAILURE":
      break
  }
  return newState
}

export default TeacherReducer
