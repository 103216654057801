import React, { Fragment, Suspense, lazy, useState, useEffect } from "react"
import { MuiThemeProvider, CssBaseline } from "@material-ui/core"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { getUserDataById } from "../src/Redux/login/loginAction";
import { connect } from "react-redux";
import theme from "./theme"
import GlobalStyles from "./GlobalStyles"
import "./main.css"
import "./App.css"
import * as serviceWorker from "./serviceWorker"
import Pace from "./shared/components/Pace"
import { Provider } from "react-redux"
import { createStore, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./Redux/rootReducer"

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"))

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"))

const store = createStore(rootReducer, applyMiddleware(thunk))

function App(props) {

  useEffect(() => {
    if(props?.userid !== undefined && props?.userid !== null){
      props.getUserDataById(localStorage.getItem("seletedUseridBySA") ? localStorage.getItem("seletedUseridBySA") : props?.userid);
    }
  }, []);
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        {/* <Provider store={store}> */}
          <CssBaseline />
          <GlobalStyles />
          <Pace color={theme.palette.primary.light} />
          <Suspense fallback={<Fragment />}>
            <Switch>
              <Route path="/c">
                <LoggedInComponent />
              </Route>
              <Route>
                <LoggedOutComponent />
              </Route>
            </Switch>
          </Suspense>
        {/* </Provider> */}
      </MuiThemeProvider>
    </BrowserRouter>
  )
}

serviceWorker.register()

const mapStateToProps = (state) => {
  return {
    userid: state.login.userid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserDataById: (id) => dispatch(getUserDataById(id)),
  };
};

export default connect(mapStateToProps, 
  mapDispatchToProps
)(App);
