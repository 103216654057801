import { GET_SCHOOL_DIRECTORY } from "./SchoolDirectoryAction";

const initialSchoolDirectory = {
  schoolDirectoryReportData : []
}
const schoolDirectoryReducer = (state = initialSchoolDirectory, action) => {
  var newState = { ...state };
  switch (action.type) {
    case GET_SCHOOL_DIRECTORY:
      newState = { ...state, schoolDirectoryReportData:action.payload}
      break

    case 'getSchoolDirectoryClass_SUCCESS':
      newState = {  ...state,schoolDirectoryReportDataClass: action.array }
      break
    
  }
  return newState;
};

export default schoolDirectoryReducer;
