export const GET_ENROLL_STUDENT_REQUESTED = "GET_ENROLL_STUDENT_REQUESTED"
export const GET_ENROLL_STUDENT_SUCCESS = "GET_ENROLL_STUDENT_SUCCESS"
export const GET_ENROLL_STUDENT_FAILURE = "GET_ENROLL_STUDENT_FAILURE"
export const UPDATE_ENROLL_STUDENT_REQUESTED = "UPDATE_ENROLL_STUDENT_REQUESTED"
export const UPDATE_ENROLL_STUDENT_SUCCESS = "UPDATE_ENROLL_STUDENT_SUCCESS"
export const UPDATE_ENROLL_STUDENT_FAILURE = "UPDATE_ENROLL_STUDENT_FAILURE"

export const GET_CLASSLIST_SUCCESS = "GET_CLASSLIST_SUCCESS"

export const SELECT_STUDENT_TO_ENROLL = "SELECT_STUDENT_TO_ENROLL"
export const SELECT_CLASSITEM_FOR_ENROLL = "SELECT_CLASSITEM_FOR_ENROLL"
export const UNSELECT_CLASSITEM_FOR_ENROLL = "UNSELECT_CLASSITEM_FOR_ENROLL"