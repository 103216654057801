import axios from "axios"
import { BASE_URL_API_CONSTANT } from "./../baseUrl/ApiConstants"
import { BASE_URL_AUTH_API_CONSTANT } from "./../baseUrl/ApiConstants"
var CryptoJS = require("crypto-js")

const sampleUser = [
  {
    username: "admin@clever.com",
    password: "clever",
    role: "admin",
    twofactor: false
  },
  {
    username: "parent@clever.com",
    password: "clever",
    role: "parent",
    twofactor: false
  },
  {
    username: "teacher@clever.com",
    password: "clever",
    role: "teacher",
    twofactor: false
  },
  {
    username: "admin1@clever.com",
    password: "clever",
    role: "admin",
    twofactor: true
  }
]
export const isInquiryLogin = (email, password) => {
  return password == "inquiry"
}

export const performLogin = (email, password) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(LoginRequested)
    var qs = require("qs")
    var data = qs.stringify({
      username: email.toLowerCase(),
      password: CryptoJS.AES.encrypt(password, "AIzaSyC18qeQt-PgGFx9g_S9v5XF2H3SdCK0ry4").toString(),
      grant_type: "password"
    })
    axios({
      method: "post",
      url: BASE_URL_AUTH_API_CONSTANT + `/login`,
      data: data,
      headers: {
        Authorization: "Basic bnVsbDpudWxs",
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(res => {
        const AuthToken = res.data.access_token

        localStorage.setItem("AuthToken", AuthToken)
        dispatch(LoginSuccess(res.data.access_token))

        resolve(res.data)
      })
      .catch(err => {
        //  alert("Invalid user id or password!")
        dispatch(LoginFailure(err.message))
        reject(err.message)
      })
  })
}

export const getUserDataToken = (token, role) => dispatch => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: BASE_URL_API_CONSTANT + `/userDatabytoken/` + token
    })
      .then(res => {
        let topRole
        if (localStorage.getItem("userRole") == "undefined" || res?.data?.OAuthUser?.current_role === 0 || res?.data?.OAuthUser?.current_role === null) {
          topRole = getTopRole(res?.data?.OAuthUser?.userMultiRoles)?.role?.name
        } else if (localStorage.getItem("userRole") == "undefined" || res.data.OAuthUser.current_role === 1) {
          topRole = getTopRole(res?.data?.OAuthUser?.userMultiRoles)?.role?.name
        }
        else if ((role != undefined && role != null && role != "") || res?.data?.OAuthUser?.current_role > 0) {
          topRole = res?.data?.OAuthUser?.userMultiRoles.find(e => e.roleId == res?.data?.OAuthUser?.current_role)?.role.name
        } else {
          let IsValid = localStorage.getItem("userRole")
          topRole = checkIsValid(IsValid, res?.data?.OAuthUser?.userMultiRoles)?.role?.name
        }
        localStorage.setItem("schoolIcon", res.data?.OAuthUser?.school?.schoolLogo)
        dispatch(getUserModel(topRole, res?.data?.OAuthUser?.is_verified, res?.data?.userId, res?.data?.OAuthUser?.firstName, res?.data?.OAuthUser?.lastName, res?.data?.OAuthUser?.userMultiRoles, res?.data?.OAuthUser?.school_id, res?.data?.OAuthUser?.school?.schoolLogo, res?.data?.OAuthUser?.email))
        if (topRole == "Superadmin") {
          localStorage.setItem("superAdminId", res?.data?.userId)
        }
        if (topRole == "Parent2") {
          localStorage.setItem("userid", res.data.OAuthUser.parent1_id)
        }
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

const getTopRole = userMultiRoles => {
  let flag = userMultiRoles.find(e => e.roleId == 1)
  if (flag == undefined) {
    flag = userMultiRoles.find(e => e.roleId == 2)
  }
  if (flag == undefined) {
    flag = userMultiRoles.find(e => e.roleId == 3)
  }
  if (flag == undefined) {
    flag = userMultiRoles.find(e => e.roleId == 4)
  }
  if (flag == undefined) {
    flag = userMultiRoles.find(e => e.roleId == 5)
  }
  if (flag == undefined) {
    flag = userMultiRoles.find(e => e.roleId == 6)
  }

  return flag
}

const checkIsValid = (name, userMultiRoles) => {
  let flag = userMultiRoles.find(e => e.role.name == name)
  if (flag != undefined) {
    return flag
  } else {
    localStorage.clear()
    window.location = "/login"
  }
}

export const getUserDataById = (id, role) => dispatch => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: BASE_URL_API_CONSTANT + `/userDatabyid/` + id,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
    })
      .then(res => {
        //localStorage.setItem("schoolId", res?.data.school_id);
        let topRole
        let userid
        let superAdminId
        if (localStorage.getItem("userRole") == "undefined" || res.data.current_role === 0 || res.data.current_role === null) {
          topRole = getTopRole(res.data.userMultiRoles).role.name
        } else if (localStorage.getItem("userRole") == "undefined" || res?.data?.current_role === 1) {
          topRole = getTopRole(res.data.userMultiRoles)?.role?.name
        }
        else if ((role != undefined && role != null && role != "") || res.data.current_role > 1) {
          topRole = res.data.userMultiRoles.find(e => e.roleId == res.data.current_role)?.role?.name;
        } else {
          let IsValid = localStorage.getItem("userRole")
          topRole = checkIsValid(IsValid, res.data.userMultiRoles).role.name
        }
        //topRole = getTopRole(res.data.userMultiRoles).role.name
        if (topRole == "Superadmin") {
          localStorage.setItem("superAdminId", res.data.id)
          superAdminId = res.data.id
        }
        if (topRole == "Parent2") {
          localStorage.setItem("userid", res.data.parent1_id)
          userid = res.data.parent1_id
        } else {
          userid = res.data.id
        }
        localStorage.setItem("schoolIcon", res.data?.school?.schoolLogo)
        dispatch(getUserModel(topRole, res?.data.is_verified, userid, res.data.firstName, res.data.lastName, res.data.userMultiRoles, res?.data.school_id, res.data?.school?.schoolLogo, res.data.username))
        // localStorage.setItem("userRole", topRole);
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const updateCurrentRole = (userId, currentRole) => dispatch => {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: BASE_URL_API_CONSTANT + `/updatecurrentrole`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
      },
      data: {
        userId: userId,
        current_role: currentRole
      }
    })
      .then(res => {
        //   dispatch(sendpasswordtoemail(true))
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const createPassword = (username, oldPass, newPass) => dispatch => {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: BASE_URL_API_CONSTANT + `/changepassword`,
      data: {
        password: oldPass,
        username: username.toLowerCase(),
        newpassword: newPass
      }
    })
      .then(res => {
        dispatch(changePassword(true))
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}

export const passwordChange = (email, password, userOtp) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: BASE_URL_API_CONSTANT + `/passwordChange`,
      data: {
        email: email.toLowerCase(),
        password: password,
        userOtp: userOtp,
      },
    })
      .then((res) => {
        dispatch(changePassword(true));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const createPasswordForget = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: BASE_URL_API_CONSTANT + `/sendpasswordtoemail`,
      data: {
        email: email
      }
    })
      .then(res => {
        dispatch(sendpasswordtoemail(true))
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}


export const getClassID = (id, role) => dispatch => {
  return new Promise((resolve, reject) => {
    const url = role === 'Parent' ? 'getClassByStudentID' : 'getTeacherDetailsByUserId'
    axios({
      method: "GET",
      url: BASE_URL_API_CONSTANT + `/${url}/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
      }
    })
      .then(res => {
        dispatch(getClassIDSuccess(res.data?.data[0]?.class_id))
        // localStorage.setItem("classID", res.data?.data[0]?.class_id);
        resolve(res.data)
      })
      .catch(err => {
        reject(err.message)
      })
  })
}
export const getRoleFromStore = role => {
  return dispatch => {
    dispatch(RoleFromStoreSuccess(role))
  }
}
const RoleFromStoreSuccess = role => {
  return {
    type: "ROLE_FROM_STORE",
    array: role
  }
}


export const verifyemail = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: BASE_URL_API_CONSTANT + `/verifyemail/${email}`,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};


const LoginRequested = () => {
  return {
    type: "LOGIN_REQUEST",
    loading: true
  }
}
const LoginSuccess = token => {
  return {
    type: "LOGIN_SUCCESS",
    loading: false,
    token: token
  }
}
const LoginFailure = error => {
  return {
    type: "LOGIN_FAILURE",
    error: error,
    loading: false
  }
}

const getUserModel = (role, isNewUser, userid, firstname, lastname, userMultiRoles, school_id, schoolLogo, UserEmail, isDemo,
  createdAt) => {
  return {
    type: "GETUser_success",
    role: role,
    newUser: isNewUser,
    userid: userid,
    firstname: firstname,
    lastname: lastname,
    userMultiRoles: userMultiRoles,
    school_id: school_id,
    schoolLogo: schoolLogo,
    UserEmail: UserEmail,
    isDemo: isDemo,
    createdAt: createdAt
  };
};

const changePassword = flag => {
  return {
    type: "PasswordChange_success",
    changeFlag: flag
  }
}

const sendpasswordtoemail = flag => {
  return {
    type: "sendpasswordtoemail_success",
    changeFlag: flag
  }
}

const getClassIDSuccess = classID => {
  return {
    type: "getClassIDSuccess",
    classID: classID
  }
}
