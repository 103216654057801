const initialState = {
    admissionReportCurrentStudentsData : []
}

const AdmissionsReportCurrentStudentsReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'AdmissionReportCurrentStudents_REQUEST':
            break
        case 'AdmissionReportCurrentStudents_SUCCESS':
            newState = { ...state, admissionReportCurrentStudentsData:action.payload}
            break
        case 'admissionReportCurrentStudents_FAILURE':
            newState = {  ...state, admissionReportCurrentStudentsData: action.payload}
            break
        case 'getAdmissionReportCurrentStudentsClass_SUCCESS':
            newState = {  ...state,admissionReportCurrentStudentsDataClass: action.array }
            break
    }
    return newState;
}

export default AdmissionsReportCurrentStudentsReducer;