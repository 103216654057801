const initialState = {
  path: "#",
  error: null,
  token: "",
  loading: false,
  role: "",
  isNewUser: "",
  changeFlag: false,
  multi_users: [],
  roleData : []
};

const LoginReducer = (state = initialState, action) => {
  var newState = { ...state };
  switch (action.type) {
    case "LOGIN_REQUEST":
      newState = { loading: action.loading, error: null };
      break;
    case "LOGIN_SUCCESS":
      newState = { error: null, loading: action.loading, token: action.token };
      break;
    case "LOGIN_FAILURE":
      newState = { error: action.error, loading: action.loading };
      break;
    case "GETUser_success":
      newState = {
        role: action.role,
        isNewUser: action.newUser,
        userid: action.userid,
        firstname: action.firstname,
        lastname: action.lastname,
        multi_users: action.userMultiRoles,
        school_id: action.school_id,
        schoolLogo: action.schoolLogo,
        UserEmail: action.UserEmail,
        isDemo: action.isDemo,
        createdAt: action.createdAt
      };
      break;
    case "PasswordChange_success":
      newState = { changeFlag: action.changeFlag };
      break;
    case "sendpasswordtoemail_success":
      newState = { changeFlag: action.changeFlag };
      break;
    case "getClassIDSuccess":
      newState = {...state, classID: action.classID };
      break;
    case 'ROLE_FROM_STORE':
      newState = {...state,roleData:action.array}
      break
  }
  return newState;
};

export default LoginReducer;
