const initialState = {
  CheckListArray: []
}

const checkListReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "ARRAY_REQUEST":
      break
    case "ARRAY_SUCCESS":
      newState = { CheckListArray: action.array }
      break
    case "ARRAY_FAILURE":
      break
    case "GetStepsParenetSUCCESS":
      newState = { ...state, stepsData: action.array }
      break
  }
  return newState
}

export default checkListReducer
