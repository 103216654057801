

const initialState = {
    studentListReportData: []
}

const StudentListReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'StudentListReport_REQUEST':
            break
        case 'StudentListReport_SUCCESS':
            newState = {  ...state,studentListReportData: action.array }
            break
        case 'StudentListReport_FAILURE':
            break
        case 'getStudentListReport_SUCCESS':
            newState = {  ...state,studentListReportData: action.array }
            break
            case 'getStudentListReportClass_SUCCESS':
                newState = {  ...state,studentListReportDataClass: action.array }
                break

    }
    return newState;
}

export default StudentListReducer;