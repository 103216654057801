const initialState = {
    pdfData: []
    
  }

  const PdfReducer = (state = initialState, action) => {
    var newState = { ...state }
    switch (action.type) {
      case "Pdf_list":
        let newData = [...state.pdfData, {file :action.file, id: action.id}]

        newState = {...state, pdfData : newData }
        break
     case "Pdf_file":
        newState = {...state }
        break
    }
    return newState
  }
  
  export default PdfReducer
  
