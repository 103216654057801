const initialState = {
  filesData: []
}

const uploadFileReducer = (state = initialState, action) => {
  var newState = { ...state }
  switch (action.type) {
    case "PostSchoolLevelData_SUCCESS":
      newState = { ...state, materialArray: action.array }
      break
    case "PostClassLevelData_SUCCESS":
      newState = { ...state, materialArray: action.array }
      break
    case "PostTeacherData_SUCCESS":
      newState = { ...state, materialArray: action.array }
      break
    case "PostStudentData_SUCCESS":
      newState = { ...state, materialArray: action.array }
      break
  }
  return newState
}

export default uploadFileReducer
