import {
  GET_ATTENDANCE_SUMMARY,
} from "./AttendanceSummaryReportAction";


const inititalAttendanceSummary = {
  attendanceSummaryReportData : []
}

const attendanceSummaryReportReducer = (
  state = inititalAttendanceSummary,
  action
) => {
  var newState = { ...state };
  switch (action.type) {
    case GET_ATTENDANCE_SUMMARY:
      newState =  {...state, attendanceSummaryReportData: action.payload}
      break;
    case 'getAttendanceSummaryClass_SUCCESS':
      newState = {  ...state,attendanceSummaryReportDataClass: action.array }
      break
  }
  return newState;
};

export default attendanceSummaryReportReducer;
