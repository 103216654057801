import axios from "axios";
import { BASE_URL_API_CONSTANT } from "./../../baseUrl/ApiConstants";

export const GET_ATTENDANCE_SUMMARY = "GET_ATTENDANCE_SUMMARY";


const getAttendanceSummary = (data) => {
  return {
    type: GET_ATTENDANCE_SUMMARY,
    payload: data,
  };
};

const getAttendanceSummaryClassSuccess = array => {
  return {
    type: "getAttendanceSummaryClass_SUCCESS",
    array: array
  }
}


export const getAttendanceSummaryApi = (school_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: BASE_URL_API_CONSTANT + `/getAttendanceSummaryReport/${school_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      })
        .then((res) => {
          dispatch(getAttendanceSummary(res.data));
          resolve(res.data);
        })
        .catch((err) => reject(err.message));
    });
  };
};

export const getAttendanceSummaryByClass = data => {
  return (dispatch) => {
      return new Promise((resolve, reject) => {
          axios({
              method: 'POST',
              url: BASE_URL_API_CONSTANT + `/getattendanceSummaryReportByclass`,
              data:data,
              headers: { 
                  // Authorization: `Bearer ${token}` 
                  Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
                }
          }).then(res => {
            console.log("clg result", res);
              dispatch(getAttendanceSummaryClassSuccess(res.data));
              resolve(res.data);
          }).catch(err => 
            {
              console.log("clg error", err)
              reject(err.message)
            });
      })

}
}
