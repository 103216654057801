import { GET_SCHOOL_DIRECTORY_FORM } from "./SchoolDirectoryFormAction";

const initialSchoolDirectoryForm = [];
const schoolDirectoryFormReducer = (state = initialSchoolDirectoryForm, action) => {
  switch (action.type) {
    case GET_SCHOOL_DIRECTORY_FORM:
      return {
        ...state,
        schoolDirectoryForm: action.payload,
      };
    default:
      return state;
  }
};

export default schoolDirectoryFormReducer;
