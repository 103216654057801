import { GET_CLASSROOM_NAME_DIRECTORY } from "./ClassroomNameDirectoryAction";

const initialClassroomNameDirectory = [];
const ClassroomNameDirectoryReducer = (state = initialClassroomNameDirectory, action) => {
  switch (action.type) {
    case GET_CLASSROOM_NAME_DIRECTORY:
      return {
        ...state,
        schoolDirectory: action.payload,
      };
    default:
      return state;
  }
};

export default ClassroomNameDirectoryReducer;
