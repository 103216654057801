import {POST_EMERGENCY_CONTACT_SUCCESS, GET_EMERGENCY_CONTACT_SUCCESS} from './EmergencyContactType'

const initialState = {
    emergencyContactArray: []
  }
  
  const EmergencyContactReducer = (state = initialState, action) => {
    var newState = { ...state }
    switch (action.type) {
        case GET_EMERGENCY_CONTACT_SUCCESS:
        newState = { ...state, emergencyContactArray: action.array }
        break
    }
    return newState
  }
  
  export default EmergencyContactReducer
  