import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import rootReducer from "./Redux/rootReducer"
import { createStore, applyMiddleware, combineReducers } from "redux"
const store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store={store}>
  <App />
  </Provider>
  ,
  document.getElementById("root")
);
