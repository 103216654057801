export const GET_PROMOTE_STUDENT_REQUESTED = "GET_PROMOTE_STUDENT_REQUESTED"
export const GET_PROMOTE_STUDENT_SUCCESS = "GET_PROMOTE_STUDENT_SUCCESS"
export const GET_PROMOTE_STUDENT_FAILURE = "GET_PROMOTE_STUDENT_FAILURE"
export const UPDATE_PROMOTE_STUDENT_REQUESTED = "UPDATE_PROMOTE_STUDENT_REQUESTED"
export const UPDATE_PROMOTE_STUDENT_SUCCESS = "UPDATE_PROMOTE_STUDENT_SUCCESS"
export const UPDATE_PROMOTE_STUDENT_FAILURE = "UPDATE_PROMOTE_STUDENT_FAILURE"

export const GET_CLASSLIST_FOR_PROMOTE_SUCCESS = "GET_CLASSLIST_FOR_PROMOTE_SUCCESS"

export const SELECT_STUDENT_TO_PROMOTE = "SELECT_STUDENT_TO_PROMOTE"
export const SELECT_STUDENT_TO_EMPTY = "SELECT_STUDENT_TO_EMPTY"
export const SELECT_CLASSITEM_FOR_PROMOTE = "SELECT_CLASSITEM_FOR_PROMOTE"
export const UNSELECT_CLASSITEM_FOR_PROMOTE = "UNSELECT_CLASSITEM_FOR_PROMOTE"