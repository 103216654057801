import { GET_APPROVED_PICKUP_SUCCESS} from './ApprovedPickupListTypes'

const initialState = {
    approvedPickupArray: []
  }
  
  const ApprovedPickupListReducer = (state = initialState, action) => {
    var newState = { ...state }
    switch (action.type) {
        case GET_APPROVED_PICKUP_SUCCESS:
        newState = { ...state, approvedPickupArray: action.array }
        break
    }
    return newState
  }
  
  export default ApprovedPickupListReducer
  